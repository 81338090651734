<template>
  <div class="main">
    <div class="system_list">
      <!-- 选择器 -->
      <div class="select_box">
        <!-- 系统编号 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="id_device"
          placeholder="请输入系统编号"
        ></el-input>

        <!-- 系统状态 -->
        <el-select class="ipt_box mr12 mt12" v-model="state" placeholder="请选择系统状态">
          <el-option label="在线" :value="1"></el-option>
          <el-option label="离线" :value="4"></el-option>
        </el-select>

        <!-- 项目名称 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="id_project"
          placeholder="请选择项目名称"
        >
          <el-option
            v-for="ite in projectList"
            :key="ite.id"
            :label="ite.name_project"
            :value="ite.id"
          ></el-option>
        </el-select>

        <!-- 系统名称 -->
        <el-input
          v-model="name"
          class="ipt_box mr12 mt12"
          placeholder="请输入系统名称"
        ></el-input>

        <!-- 系统类型 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="device_type"
          placeholder="请选择系统类型"
        >
          <el-option
            v-for="ite in [
              { id: 1, text: '无线版' },
              { id: 0, text: '普通版' },
            ]"
            :key="ite.id"
            :label="ite.text"
            :value="ite.id"
          ></el-option>
        </el-select>

        <div class="mr12 mt12">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button size="small" type="primary" plain @click="reset">重置</el-button>
          <el-button size="small" plain type="success" @click="exportExcel"
            >导出</el-button
          >
        </div>
      </div>

      <div class="table_box">
        <!-- 表格 -->
        <el-table
          :data="systemList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb',
            height: '40px',
          }"
          :stripe="true"
          size="small"
        >
          <el-table-column prop="id_device" label="系统编号"></el-table-column>
          <template v-if="role === 100">
            <el-table-column prop="name_operator" label="运营商"></el-table-column>
          </template>

          <el-table-column prop="name_project" label="项目名称"></el-table-column>
          <el-table-column label="系统名称">
            <template v-slot="{ row }">
              <span
                style="color: #2a47b0"
                v-if="row.heater_device_type === 1"
                @click="showSensorList(row)"
                class="cursor"
                >{{ row.name }}</span
              >
              <span v-else>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="系统状态">
            <template v-slot="scope">
              <span :class="scope.row.state === 1 ? 'state1' : 'state4'">
                {{ scope.row.state | stateFmt }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="系统类型">
            <template v-slot="{ row }">
              <span>{{ row.heater_device_type ? "无线版" : "普通版" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="版本号">
            <template v-slot="{ row }">
              <span>{{ row.heater_device_ver || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="管理员">
            <template v-slot="scope">
              {{ getAdminName(scope.row.admin) }}
            </template>
          </el-table-column>
          <el-table-column label="更新时间">
            <template v-slot="scope">
              <span>
                {{ scope.row.time_update || "--" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" prop="time_create"></el-table-column>

          <el-table-column label="操作" width="240">
            <template v-slot="scope">
              <div class="btn_box">
                <span class="edit_btn" @click="editSystem(scope.row)">
                  <img src="../../../assets/edit_8.png" alt="" />
                  <span class="font">修改</span>
                </span>
                <span class="reset_btn" @click="initSystem(scope.row)">
                  <img src="../../../assets/reset_icon.png" alt="" />
                  <span class="font">初始化</span>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!--分页-->
        <div class="pagination_box">
          <el-pagination
            @size-change="sizeChange"
            @current-change="CurrentChange"
            :current-page="page_id + 1"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="page_num"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <EditSystem ref="editSystemRef" @refresh="getSystemList" />

    <!-- 导出excel -->
    <ExportExcel ref="exportExcelRef" />
    <!-- 感应器列表 -->
    <SensorList ref="sensorListRef" />
  </div>
</template>

<script>
import { GetProjectList, GetConfigSystem, InitConfig } from "@/api";
import ExportExcel from "@/components/export_excel/export_excel.vue";
import EditSystem from "./dialog/edit_system.vue";
import SensorList from "./dialog/sensor_list.vue";

export default {
  name: "system_list",
  components: { EditSystem, ExportExcel, SensorList },
  data() {
    return {
      role: "",
      page_id: 0,
      page_num: 20,
      total: 0,
      // 系统编号
      id_device: "",
      // 系统状态
      state: "",
      // 设备类型 1:无线版 0普通版
      device_type: "",
      // 项目
      id_project: "",
      // 系统名称
      name: "",
      // 项目列表
      projectList: [],
      // 系统列表
      systemList: [],
    };
  },

  methods: {
    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s;
      this.page_id = 0;
      this.getSystemList();
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1;
      this.getSystemList();
    },

    // 查询
    search() {
      this.page_id = 0;
      this.getSystemList();
    },

    // 重置
    reset() {
      this.page_id = 0;
      this.id_device = "";
      this.state = "";
      this.device_type = "";
      this.id_project = "";
      this.name = "";
      this.getSystemList();
    },

    // 获取项目列表
    async getProject() {
      const { ret, data, msg } = await GetProjectList();
      if (ret !== 0) {
        return this.$message.error(msg || "获取项目列表失败!");
      }
      this.projectList = data.data;
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num,
      };
      if (this.id_device.trim()) {
        params.id_device = this.id_device.trim();
      }
      if (this.state !== "") {
        params.state = this.state;
      }
      if (this.id_project) {
        params.id_project = this.id_project;
      }
      if (this.device_type !== "") {
        params.heater_device_type = this.device_type;
      }
      if (this.name.trim()) {
        params.name = this.name.trim();
      }
      return params;
    },

    // 获取已配置的系统
    async getSystemList() {
      const params = this.getParams();
      const { ret, data, msg } = await GetConfigSystem(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.total = data.cnt_all;
      this.systemList = data.data;
    },

    // 修改系统
    editSystem(row) {
      this.$refs.editSystemRef.edit(row, this.projectList);
    },

    // 初始化系统
    async initSystem(row) {
      const result = await this.$confirm(
        "初始化后设备信息将会被清除, 设备会被重置为未配置, 是否继续?",
        "初始化提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(() => "cancel");
      if (result === "cancel") return;
      const { ret, data, msg } = await InitConfig({
        device_array: [row.id_device],
      });
      if (ret !== 0) {
        return this.$message.error(msg || "初始化设备失败!");
      }
      this.$message.success("初始化成功");
      this.getSystemList();
    },

    // 导出excel
    exportExcel() {
      const params = this.getParams();
      this.$refs.exportExcelRef.export({
        fn: GetConfigSystem,
        params,
        dataMap: ["data"],
        totalMap: ["cnt_all"],
        keyMap: {
          id_device: "系统编号",
          name_project: "项目名称",
          name: "系统名称",
          state: "系统状态",
          heater_device_type: "系统类型",
          real_name: "管理员",
          time_update: "更新时间",
          time_create: "创建时间",
        },
        valueFmt: {
          state(v) {
            switch (v) {
              case 1:
                return "在线";
              default:
                return "离线";
            }
          },
          heater_device_type(v) {
            return v === 1 ? "无线版" : "普通版";
          },
        },
      });
    },

    // 对管理员的名称进行格式化
    getAdminName(arr) {
      if (arr.length) {
        const names = [];
        arr.map((i) => {
          names.push(i.real_name);
        });
        return names.join(" | ");
      } else {
      }
      return "--";
    },

    // 显示感应器列表
    showSensorList(row) {
      this.$refs.sensorListRef.open(row);
    },
  },

  created() {
    this.role = this.$store.getters.role;
    this.getProject();
    this.getSystemList();
  },

  filters: {
    stateFmt(v) {
      switch (v) {
        case 1:
          return "在线";
        default:
          return "离线";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.system_list {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: $radius;
  padding: $padding;
}

.table_box {
  padding: 0 12px;
  margin-top: 12px;

  .btn_box {
    display: flex;
    align-items: center;

    .edit_btn {
      display: flex;
      align-items: center;
      margin: 0 5px;
      cursor: pointer;

      img {
        width: 23px;
        height: 23px;
      }

      .font {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2a47b0;
      }
    }

    .reset_btn {
      display: flex;
      align-items: center;
      margin: 0 5px;
      cursor: pointer;

      img {
        width: 23px;
        height: 23px;
      }

      .font {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2a47b0;
      }
    }
  }

  .state1 {
    color: #2a47b0;
  }

  .state3,
  .state4 {
    color: red;
  }
}
</style>
