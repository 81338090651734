<template>
  <el-dialog
    title="配置"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="reset"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleFormRef"
      label-width="100px"
    >
      <el-form-item label="系统编号：">
        <el-input
          v-model="ruleForm.id_device"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item
        label="项目："
        prop="id_project"
      >
        <el-select
          v-model="ruleForm.id_project"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="ite in projectList"
            :key="ite.id"
            :label="ite.name_project"
            :value="ite.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="系统名称："
        prop="name"
      >
        <el-input
          v-model.trim="ruleForm.name"
          maxlength="16"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="系统地址："
        prop="addr"
      >
        <el-input
          v-model.trim="ruleForm.addr"
          maxlength="24"
        ></el-input>
      </el-form-item>

      <el-form-item label="远传表：">
        <el-input
          v-model.trim="ruleForm.meter_name"
          maxlength="24"
        ></el-input>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="confirmEdit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { ConfigSystem3 } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 项目列表
      projectList: [],
      // 表单
      ruleForm: {
        id_device: '',
        id_project: '',
        name: '',
        addr: '',
        meter_name: ''
      },
      // 规则
      rules: {
        id_project: [
          { required: true, trigger: 'change', message: '请选择项目' }
        ],
        name: [{ required: true, message: '请输入系统名称', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入系统地址', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 重置
    reset() {
      this.$refs.ruleFormRef.resetFields()
    },

    // 编辑
    edit(row, projectList) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.projectList = projectList
        this.ruleForm.id_device = row.id_device
        this.ruleForm.id_project = row.id_project
        this.ruleForm.name = row.name
        this.ruleForm.addr = row.addr
        this.ruleForm.meter_name = row.meter_name || ''
      })
    },

    // 确定编辑
    confirmEdit() {
      this.$refs.ruleFormRef.validate(async (_) => {
        if (!_) return
        const params = {
          device_array: [this.ruleForm.id_device],
          id_project: this.ruleForm.id_project,
          name: this.ruleForm.name.trim(),
          addr: this.ruleForm.addr.trim(),
          meter_name: this.ruleForm.meter_name || ''
        }
        const { ret, data, msg } = await ConfigSystem3(params)
        if (ret !== 0) {
          return this.$message.error(msg || '配置系统失败!')
        }
        this.$message.success('配置系统成功')
        this.$emit('refresh')
        this.dialogVisible = false
      })
    }
  }
}
</script>

<style lang='scss' scoped></style>
