<template>
  <el-dialog
    :title="device.name"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    center
  >
    <!-- 表格 -->
    <div class="tabel_box">
      <el-table
        :data="list"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb',
          height: '40px'
        }"
        :stripe="true"
        size="small"
      >
        <el-table-column
          prop="id"
          label="序号"
        ></el-table-column>

        <el-table-column
          prop="name"
          label="传感器"
        ></el-table-column>

        <el-table-column
          prop="val"
          label="电压(mv)"
        ></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { GetSensorDataApi } from '@/api'

export default {
  data() {
    return {
      device: {},
      // 是否显示弹窗
      dialogVisible: false,
      // 传感器表格
      list: []
    }
  },
  methods: {
    open(row) {
      this.device = row
      this.dialogVisible = true
      this.getSensorList()
    },

    // 获取传感器数据
    async getSensorList() {
      const params = {
        id_device: this.device.id_device
      }
      const { ret, data, msg } = await GetSensorDataApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const list = []
      list.push({
        id: 1,
        name: '温度传感器',
        val: data.vtemp
      })
      list.push({
        id: 2,
        name: '压力传感器',
        val: data.vpre_level
      })
      list.push({
        id: 3,
        name: '电流传感器',
        val: data.vele
      })
      this.list = list
    }
  }
}
</script>

<style lang='scss' scoped></style>
